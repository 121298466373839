import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import CoreValues from './CoreValues'
import Images from './Images'
import WordWheel from './WordWheel'
import Tool from './Tool'
import Whitespace from './Whitespace'
import Slider from './Slider'
import Video from './Video'
import App from './App'
import AltSlider from './AltSlider'
import MVO from './Mvo'
import Rules from './Rules'

const PostsShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    corevalues: CoreValues,
    images: Images,
    wordwheel: WordWheel,
    tool: Tool,
    whitespace: Whitespace,
    slider: Slider,
    altslider: AltSlider,
    video: Video,
    app: App,
    mvo: MVO,
    rules: Rules
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default PostsShell
