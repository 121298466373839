import type { Page_Flexcontent_Flex_Misc } from '~/graphql/types'
import { LosseContent as Content, LossePlaatjie as Image } from '@ubo/losse-sjedel'
import { useState } from 'react'
import clsx from 'clsx'

export default function Rules({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  const [currentIndex, setCurrentIndex] = useState(-1)

  return (
    <section id="Rules">
      <div className="container py-10 md:py-16 lg:py-24">
        <Content className="content">{fields.description}</Content>
        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-6 md:gap-10 pt-6 lg:pt-14">
          {fields.blocks?.map((block, index) => {
            const active = currentIndex === index

            return (
              <div
                className="col-span-1 h-auto relative items-center flex md:block gap-4 sm:gap-8 flex-col sm:flex-row md:flex-col"
                key={index}
              >
                <div
                  onClick={() => setCurrentIndex(index)}
                  onMouseEnter={() => setCurrentIndex(index)}
                  onMouseLeave={() => setCurrentIndex(-1)}
                  className="bg-sprout aspect-square min-w-[130px] w-full sm:min-w-[200px] md:min-w-[unset] flex justify-center items-center"
                >
                  <div
                    className={clsx(active ? 'opacity-0 hidden' : 'opacity-100 block', 'flex flex-col h-full justify-between items-center')}
                  >
                    <div className="flex items-center h-full">
                      <Image className="w-full h-auto md:w-[100px]" src={block?.icon} alt="core-value-icon" />
                    </div>
                    <div className="inline-flex items-center pb-5 xl:pb-10 font-nord font-medium text-lg sm:text-xl lg:text-2xl text-center px-4">
                      <span>{block?.title}</span>
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-2">
                        <path
                          d="M10.5 0C8.4233 0 6.39323 0.615814 4.66652 1.76957C2.9398 2.92332 1.59399 4.5632 0.79927 6.48182C0.0045495 8.40045 -0.203386 10.5116 0.201759 12.5484C0.606904 14.5853 1.60693 16.4562 3.07538 17.9246C4.54383 19.3931 6.41476 20.3931 8.45156 20.7982C10.4884 21.2034 12.5996 20.9955 14.5182 20.2007C16.4368 19.406 18.0767 18.0602 19.2304 16.3335C20.3842 14.6068 21 12.5767 21 10.5C20.997 7.71615 19.8898 5.04718 17.9213 3.0787C15.9528 1.11022 13.2839 0.00301095 10.5 0V0ZM10.5 19.25C8.76942 19.25 7.07769 18.7368 5.63876 17.7754C4.19983 16.8139 3.07833 15.4473 2.41606 13.8485C1.75379 12.2496 1.58051 10.4903 1.91813 8.79296C2.25575 7.09563 3.08911 5.53653 4.31282 4.31282C5.53653 3.08911 7.09563 2.25575 8.79296 1.91813C10.4903 1.58051 12.2496 1.75379 13.8485 2.41605C15.4473 3.07832 16.8139 4.19983 17.7754 5.63876C18.7368 7.07769 19.25 8.76941 19.25 10.5C19.2475 12.8199 18.3248 15.044 16.6844 16.6844C15.044 18.3248 12.8199 19.2475 10.5 19.25Z"
                          fill="#000"
                        />
                        <path
                          d="M10.4999 8.75049H9.62486C9.3928 8.75049 9.17024 8.84268 9.00614 9.00677C8.84205 9.17086 8.74986 9.39342 8.74986 9.62549C8.74986 9.85755 8.84205 10.0801 9.00614 10.2442C9.17024 10.4083 9.3928 10.5005 9.62486 10.5005H10.4999V15.7505C10.4999 15.9826 10.592 16.2051 10.7561 16.3692C10.9202 16.5333 11.1428 16.6255 11.3749 16.6255C11.6069 16.6255 11.8295 16.5333 11.9936 16.3692C12.1577 16.2051 12.2499 15.9826 12.2499 15.7505V10.5005C12.2499 10.0364 12.0655 9.59124 11.7373 9.26305C11.4091 8.93486 10.964 8.75049 10.4999 8.75049Z"
                          fill="#000"
                        />
                        <path
                          d="M10.5 6.99951C11.2249 6.99951 11.8125 6.41189 11.8125 5.68701C11.8125 4.96214 11.2249 4.37451 10.5 4.37451C9.77513 4.37451 9.1875 4.96214 9.1875 5.68701C9.1875 6.41189 9.77513 6.99951 10.5 6.99951Z"
                          fill="#000"
                        />
                      </svg>
                    </div>
                  </div>

                  <div
                    className={clsx(
                      active ? 'translate-y-0 opacity-100 block p-4' : 'translate-y-3 opacity-0 hidden',
                      'px-6 transition-all'
                    )}
                  >
                    <span className="text-xl mb-7 text-center font-bold w-full inline-block">{block?.title}</span>
                    <Content className="content children-p:text-base">{block?.description}</Content>
                  </div>
                </div>

                {active && (
                  <div onClick={() => setCurrentIndex(-1)} role="button" className="absolute top-5 right-5 md:hidden">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M21.0533 1.80334L19.1965 -0.0534058L10.4999 8.64409L1.80328 -0.0534058L-0.0534668 1.80334L8.64403 10.5L-0.0534668 19.1966L1.80328 21.0533L10.4999 12.3558L19.1965 21.0533L21.0533 19.1966L12.3558 10.5L21.0533 1.80334Z"
                        fill="#FF5C39"
                      />
                    </svg>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
